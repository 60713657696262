import React, { useState, useMemo } from 'react';
import { DayPicker } from 'react-day-picker';

import moment from "moment/moment";
import CustomYearGrid from "src/components/MegaFilter/TimePeriods/CustomYearGrid";
import CustomCenturyGrid from "src/components/MegaFilter/TimePeriods/CustomCenturyGrid";

export default ({ selectedRange, onDayClick, dateMonth, setDateMonth, minDate, maxDate }) => {
  const [calendarMode, setCalendarMode] = useState(null);

  const fromDate = minDate;
  const toDate = maxDate;

  const startMonth = useMemo(() => {
    return fromDate.clone().startOf('month')
  }, [fromDate]);

  const endMonth = useMemo(() => {
    return toDate.clone().endOf('month')
  }, [toDate]);

  const handleChangeMonth = (x) => {
    const currentMonth = moment(dateMonth);
    const newDateMonth = currentMonth.set('month', x);

    setCalendarMode(null);
    setDateMonth(newDateMonth.toDate());
  }

  const handlePrevMonth = () => {
    const newDateMonth = moment(dateMonth).subtract(1, 'month');
    setDateMonth(newDateMonth.toDate());
  }

  const handleNextMonth = () => {
    const newDateMonth = moment(dateMonth).add( 1, 'month');
    setDateMonth(newDateMonth.toDate());
  }

  const handleChangeYear = (x) => {
    const currentMonth = moment(dateMonth);
    const newDateMonth = currentMonth.set('year', x);

    setCalendarMode('months');
    setDateMonth(newDateMonth.toDate());
  }

  const components = useMemo(() => {
    const settings = { Nav: () => null };
    const m = moment(dateMonth);

    switch (calendarMode) {
      case 'years': {
        settings.MonthGrid = () => (
          <CustomCenturyGrid
            startDate={fromDate.clone()}
            endDate={toDate.clone()}
            onYearChange={handleChangeYear}
          />
        )
        settings.CaptionLabel = () => (<><div className='title'/></>)
        break;
      }
      case 'months': {
        const formattedDate = m.format('YYYY');

        settings.MonthGrid = () => (
          <CustomYearGrid
            startDate={fromDate.clone()}
            endDate={toDate.clone()}
            dateMonth={dateMonth}
            onMonthChange={handleChangeMonth}
          />
        )
        settings.CaptionLabel = () => (
          <>
            <div className='title'>
              <button onClick={() => setCalendarMode('years')} className='year-button'>
                {formattedDate}
              </button>
            </div>
          </>
        )
        break;
      }
      default: {
        const formattedDate = m.format('MMMM YYYY');
        const startOfMonth = moment(dateMonth).clone().startOf('month');
        const endOfMonth = moment(dateMonth).clone().endOf('month');
        const disabledPrev = startOfMonth <= startMonth;
        const disabledNext = endOfMonth >= endMonth;

        settings.CaptionLabel = () => (
          <>
            <div className='arrow-left'>
              {!disabledPrev && <button onClick={handlePrevMonth} className='month-button'>‹</button>}
            </div>
            <div className='title'>
              <button onClick={() => setCalendarMode('months')} className='month-button'>
                {formattedDate}
              </button>
            </div>
            <div className='arrow-right'>
              {!disabledNext && <button onClick={handleNextMonth} className='month-button'>›</button>}
            </div>
          </>
        )
      }
    }

    return settings;
  }, [calendarMode, dateMonth]);

  return (
    <DayPicker
      weekStartsOn={1}
      modifiers={{ booked: [selectedRange] }}
      modifiersClassNames={{ booked: "my-booked-class" }}
      month={dateMonth}
      disabled={{ before: fromDate.toDate(), after: toDate.toDate() }}
      onDayClick={onDayClick}
      components={components}
    />
  );
}