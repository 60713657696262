import { Controller } from "@hotwired/stimulus"
import NeoDialog from "../src/NeoDialog.js";

export default class CheckoutButtonDisablerContoller extends Controller {
  static targets = ['buttonToDisable']

  connect() {
    const buttonsToDisable = Array.from(this.element.querySelectorAll('.btn'));
    const billId = this.element.dataset.billId;
    this.initiator_name = "";
    this.initiator_email = "";

    const eventFunction = (name, email) => {
      const message = `The checkout process is currently being completed by ${this.initiator_name} - ${this.initiator_email}. \
      Please try again later or contact them directly.`;
      NeoDialog.confirm(message, 'Ok');
    };

    this.subscription = App.cable.subscriptions.create(
      { channel: "BillStatusChannel", bill_id: billId }, 
      { received: (cmd) => {
        buttonsToDisable.forEach((buttonsToDisable) => {
          const bill_timer_in_progress = cmd.data && cmd.data.status === 'in_progress';
          this.initiator_name = cmd.data.initiator ? cmd.data.initiator.initiator_name : "";
          this.initiator_email = cmd.data.initiator ? cmd.data.initiator.initiator_email : "";
  
          if (cmd.command == 'status' && bill_timer_in_progress) {
            buttonsToDisable.classList.add("disabled");
            this.element.addEventListener('click', eventFunction);
          } else {
            this.element.removeEventListener('click', eventFunction)
            buttonsToDisable.classList.remove("disabled");
          }
        });
      }
    });
  }
}
